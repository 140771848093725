import React, { FC, useMemo } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { SwiperOptions } from 'swiper/types/swiper-options';

import UseIsSsku from '../../hooks/tenant/useIsSsku';
import styled from '../../styled';
import {
  MIDDLE_MARGIN_PX,
  SKELETON_TEXT_HEIGHT_PX,
  SKELETON_TEXT_HEIGHT_SMALL_PX,
  TINY_MARGIN_PX,
  useIsWidthUp,
} from '../../theme';
import AnimatedSkeleton from '../AnimatedSkeleton';
import AnimatedSkeletonMultiline from '../AnimatedSkeletonMultiline';
import { ExpertsSlider } from '../ExpertsSlider';
import { H2 } from '../UI/Texts';

type tileType = 'light' | 'dark';

const getCardBackground = (back, inverse = true): tileType => {
  if (inverse) {
    return back === 'Light' ? 'dark' : 'light';
  }

  return back === 'Light' ? 'light' : 'dark';
};

export const ExpertsBlock: FC<{
  data: any;
  forceDarkOnMobile: boolean;
  loading: boolean;
  swiperSettings?: SwiperOptions;
}> = ({ data: expertsData, forceDarkOnMobile = true, loading, swiperSettings }) => {
  const isMobile = !useIsWidthUp('sm');
  const isSsku = UseIsSsku();
  const background = expertsData?.background;
  const heading = expertsData?.heading;
  const description = expertsData?.description;
  const items = useMemo(() => {
    return expertsData?.items
      ? expertsData.items.map((item) => item?.expert).filter((item) => item)
      : [];
  }, [expertsData]);

  const expertTileType: tileType = forceDarkOnMobile
    ? getCardBackground(background, !isMobile)
    : getCardBackground(background, true);

  if (loading) {
    return (
      <Wrapper
        forceDarkOnMobile={forceDarkOnMobile}
        background={background}
        className={cx(background === 'Dark' ? 'dark-block' : 'light-block', 'experts-block')}
      >
        <Container>
          <Content>
            <HeadingWrapper>
              <LoadingHeading xs={SKELETON_TEXT_HEIGHT_PX} />
              <LoadingDescription
                spacer={TINY_MARGIN_PX}
                lines={3}
                xs={SKELETON_TEXT_HEIGHT_SMALL_PX}
              />
            </HeadingWrapper>
            <ExpertsSlider loading />
          </Content>
        </Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      forceDarkOnMobile={forceDarkOnMobile}
      background={background}
      className={cx(background === 'Dark' ? 'dark-block' : 'light-block', 'experts-block')}
    >
      <Container>
        <Content>
          <HeadingWrapper>
            <Heading special={isSsku} variant="h2" component="h2" align="center">
              {heading}
            </Heading>
            <Description>{description}</Description>
          </HeadingWrapper>
          <ExpertsSlider
            swiperSettings={swiperSettings}
            experts={items}
            tileType={expertTileType}
            withLinks
          />
        </Content>
      </Container>
    </Wrapper>
  );
};

export default ExpertsBlock;

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'forceDarkOnMobile' && prop !== 'background',
})`
  padding-top: 36px;
  padding-bottom: 36px;
  background: ${({ forceDarkOnMobile, theme, background }) =>
    forceDarkOnMobile
      ? theme.palette.secondary.dark
      : background === 'Dark'
      ? theme.palette.secondary.dark
      : theme.palette.secondary.light};

  &:first-child {
    margin-top: 76px;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: 72px;
    padding-bottom: 72px;
    background: ${({ background, theme }) =>
      background === 'Dark'
        ? theme.palette.common.blockBackground.main
        : theme.palette.secondary.light};

    &:first-child {
      margin-top: 120px;
    }
  }
`;
const Content = styled('div')`
  padding: 36px 10px;
`;
const HeadingWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const createHeading = (tag) => styled(tag)`
  margin-bottom: 22px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-bottom: ${MIDDLE_MARGIN_PX};
  }
`;
const Heading = createHeading(H2);
const LoadingHeading = styled(createHeading(AnimatedSkeleton))`
  max-width: 600px;
`;
const createDescription = (tag) => styled(tag)`
  font-size: 18px;
  line-height: 1.5;
  color: ${({ theme }) => theme.palette.common.text.secondaryToPrimary};
  text-align: center;
  margin-bottom: 72px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 70%;
  }
`;
const Description = createDescription(Typography);
const LoadingDescription = styled(createDescription(AnimatedSkeletonMultiline))`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
