import { gql } from '@apollo/client';

export const GET_NAVIGATION = gql`
  query GetNavigation($filter: NavigationFilter) {
    getNavigation(filter: $filter) {
      id
      tenant_id
      navigation_type
      tenant {
        name
        logo_url
        custom_css
        partner_type_id
      }
      items {
        ... on NavigationItem {
          ranking
          title
          url
          type
          item_type
        }
        ... on NavigationSection {
          item_type
          ranking
          title
          items {
            ranking
            title
            url
            type
            item_type
          }
        }

        ... on NavigationMegaMenuItem {
          item_type
          ranking
          title
          url
          type
          blocks {
            ... on MegaMenuLeftBlock {
              title
              sub_title
              external_link
              text_external_link
              item_type
              type_left_block
              items {
                ... on MegaMenuLeftListSubBlock {
                  title
                  sub_title
                  image_url
                  link
                  item_type
                }

                ... on MegaMenuLeftLinkSubBlock {
                  title
                  link
                  item_type
                }
              }
            }

            ... on MegaMenuMiddleBlock {
              title
              item_type
              informational_items {
                title
                sub_title
                item_type
              }
              clickable_items {
                title
                sub_title
                image_url
                link
                item_type
              }
            }

            ... on MegaMenuRightBlock {
              title
              item_type
              external_link
              text_external_link
              items {
                ... on MegaMenuRightVideoSubBlock {
                  title
                  item_type
                  description
                  image_url
                  link
                  play_icon
                }
              }
            }
          }
        }
      }
    }
  }
`;
