import React, { ReactNode } from 'react';

import parse from 'html-react-parser';

import tenantCircle from '../../../assets/images/tenantCircle.png';
import styled from '../../../styled';
import { SMALL_MARGIN_PX } from '../../../theme';
import { IKImage } from '../../IKImage';

const fillRegExp = new RegExp('fill="#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})"', 'g');
const strokeRegExp = new RegExp('stroke="#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})"', 'g');

interface PathwayLogoProps {
  circleUrl?: string | null;
  icon?: string | null | ReactNode;
  className?: string;
}

const PathwayLogo: React.FC<PathwayLogoProps> = ({ circleUrl, icon = '', className }) => {
  const updatedSvgContent =
    typeof icon === 'string' &&
    icon.replace(fillRegExp, 'fill="currentColor"').replace(strokeRegExp, 'stroke="currentColor"');

  return (
    <Wrapper className={className}>
      {circleUrl ? (
        <Circle className="pathwayLogo" data-testid="pathwayLogo" path={circleUrl} />
      ) : (
        <Img className="pathwayLogo" data-testid="pathwayLogo" alt="logo" src={tenantCircle} />
      )}
      {updatedSvgContent ? parse(updatedSvgContent || '') : icon}
    </Wrapper>
  );
};

export default PathwayLogo;

const Wrapper = styled('div')`
  width: 78px;
  height: 78px;
  position: relative;
  margin-bottom: ${SMALL_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 116px;
    height: 116px;
  }

  & svg,
  img:not(.pathwayLogo) {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60%;
    height: 60%;
    transform: translate(-50%, -50%);
    color: ${({ theme }) => theme.palette.common.white};
    object-fit: contain;
  }
`;
const Circle = styled(IKImage)`
  width: 100%;
  height: 100%;
`;
const Img = styled('img')`
  width: 100%;
  height: 100%;
`;
