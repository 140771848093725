import React from 'react';

import { Element, useSvgColor, Svg } from './styles';

const MoneyIcon = ({ color = '', ...props }) => {
  const svgColor = useSvgColor(color);

  return (
    <Svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      svgColor={svgColor}
    >
      <Element
        d="M3.25 5.25C2.2835 5.25 1.5 6.0335 1.5 7V17C1.5 17.9665 2.2835 18.75 3.25 18.75H20.75C21.7165 18.75 22.5 17.9665 22.5 17V7C22.5 6.0335 21.7165 5.25 20.75 5.25H4.36957H3.25Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <Element as="circle" cx="12" cy="12" r="2.625" strokeWidth="1.5" />
      <Element
        d="M5.25 6V5.25H4.5H3.25C2.2835 5.25 1.5 6.0335 1.5 7V8.25V9H2.25H3.5C4.4665 9 5.25 8.2165 5.25 7.25V6Z"
        strokeWidth="1.5"
      />
      <Element
        d="M2.25 15L1.5 15L1.5 15.75L1.5 17C1.5 17.9665 2.2835 18.75 3.25 18.75L4.5 18.75L5.25 18.75L5.25 18L5.25 16.75C5.25 15.7835 4.4665 15 3.5 15L2.25 15Z"
        strokeWidth="1.5"
      />
      <Element
        d="M22.5 15.75V15H21.75H20.5C19.5335 15 18.75 15.7835 18.75 16.75V18V18.75H19.5H20.75C21.7165 18.75 22.5 17.9665 22.5 17V15.75Z"
        strokeWidth="1.5"
      />
      <Element
        d="M19.5 5.25L18.75 5.25L18.75 6L18.75 7.25C18.75 8.2165 19.5335 9 20.5 9L21.75 9L22.5 9L22.5 8.25L22.5 7C22.5 6.0335 21.7165 5.25 20.75 5.25L19.5 5.25Z"
        strokeWidth="1.5"
      />
    </Svg>
  );
};

export default MoneyIcon;
