import { gql } from '@apollo/client';

export const GET_PAGE_BY_URL = gql`
  query PageByUrl($input: PageFilter) {
    getPageByUrl(input: $input) {
      created_at
      url
      tenant {
        id
        name
        connection
        gtm_id
        logo_url
        custom_css
        partner_type_id
      }
      blocks {
        ... on VideoListBlock {
          type
          heading
          background
          orientation
          ranking
          items {
            ranking
            video {
              id
              title
              preview_url
              duration
              description
              verse_video_id
              video_url
              difficulty {
                id
                title
                icon_url
              }
              user {
                id
                name
              }
              categories {
                title
              }
              show_bumper
            }
          }
          show_on_popups
        }
        ... on PathwayListBlock {
          type
          heading
          ranking
          with_categories
          background
          items {
            ranking
            pathway {
              id
              name
              image_url
              videos {
                id
                duration
              }
              duration
              count_videos
              slug
              categories {
                id
                title
                parent {
                  id
                  title
                }
              }
            }
          }
        }
        ... on NewsBlock {
          type
          ranking
          heading
          news_limit
          background
          button_url
          button_text
        }
        ... on ImageArrayBlock {
          type
          ranking
          heading
          desktop_image
          items {
            image
            heading
            description
          }
          button_url
          button_text
          background
          type_image
        }
        ... on ImagesAndTextArrayBlock {
          type
          ranking
          heading
          background
          items {
            ... on TextContent {
              type
              ranking
              text
              button_text
              button_url
            }
            ... on ImageContent {
              type
              ranking
              title
              alt
              image
              mobile_image
              is_background
            }
          }
          type_block
        }
        ... on PartnersBlock {
          type
          ranking
          heading
          #          description
          white_icon
          partnerType {
            id
          }
          background
          display
        }
        ... on IntroVideoBlock {
          type
          heading
          video_url
          preview_video_url
          preview_image_url
          button_text
          button_url
          gradient
          background
          description
          ranking
          heading_highlight_color
          watch_video_button_text
          usp_blocks {
            image
            seo_image_name
            label_text
          }
        }
        ... on IntroImageBlock {
          type
          view_type
          heading
          preview_image_url
          button_text
          button_url
          description
          gradient
          ranking
          usp_blocks {
            image
            seo_image_name
            label_text
          }
        }
        ... on CatalogueOfCategoriesBlock {
          ranking
          heading
          type
          background
        }
        ... on ContactDetailsBlock {
          type
          heading
          background
          ranking
          items {
            heading
            icon_url
            editable_html
            type_content
          }
        }
        ... on ContactFormBlock {
          type
          heading
          background
          ranking
          sub_title
          background_image
          items {
            question
            type
            is_sales_request
          }
          user_com_formId
          name_field
          email_field
          contact_message_field
          contact_reason_field
        }
        ... on ExpertsListBlock {
          type
          heading
          description
          background
          ranking
          items {
            expert {
              id
              avatar
              name
              slug
            }
          }
        }
        ... on SeoBlock {
          type
          seo_title
          seo_description
          share_image
        }
        ... on PricingBlock {
          type
          heading
          background
          button_text
          button_url
        }

        ... on HTMLBlock {
          type
          html_code
        }

        ... on JSBlock {
          type
          js_code
        }

        ... on QuotesListBlock {
          type
          background
          background_image
          quotes {
            title
            sub_title
            color_text
            cta_button_link
            cta_button_text
          }
        }

        ... on CTABlock {
          type
          background
          title
          sub_title
          title_text
          link
        }

        ... on ForEnterprise {
          type
          ranking
          background
          heading
          sub_title
          description
          button_text
          button_url
          content {
            title
            desktopImageUrl
            mobileImageUrl
          }
        }

        ... on AllProfessionals {
          type
          ranking
          background
          heading
          image
          content {
            title
            sub_title
            image
            link
          }
        }

        ... on TrustpilotBlock {
          type
          background
          title
          description
          trustpilot_image
          trustpilot_url
        }

        ... on ImageSliderWithText {
          type
          background
          ranking
          heading
          content {
            title
            image
            theses
          }
        }

        ... on WhatBusinessStand {
          type
          background
          ranking
          heading
          section_first {
            title
            description
          }
          section_second {
            title
            usp_icon_block {
              usp_icon_image
              usp_title
              usp_description
            }
          }
        }

        ... on MeetTheTeam {
          type
          background
          ranking
          heading
          members {
            id
            avatar
            name
            employee_job_title
            linkedin_url
            secondary_image
          }
        }

        ... on WeAreBlock {
          type
          background
          ranking
          heading
          items {
            title
            description
            icon
          }
          sliders {
            image
            label
          }
        }

        ... on TestimonialsBlock {
          type
          background
          testim_view_type
          testimonials {
            ranking
            job_title
            person_name
            person_avatar
            company_name
            person_avatar_seo_name
            company_logo
            company_logo_seo_name
            quote
            link_url
            link_title
          }
        }

        ... on FAQ {
          type
          background
          heading
          questions {
            title
            description
          }
        }

        ... on RequestDemo {
          type
          ranking
          demo_title: title
          subtitle
          subtitle_2
          about_our
          about_our_title
          partner_type {
            id
          }
          partners_title
          preview_image_url
          seo_image_name
          usercom_form_id
          form_title
          form_title_automation
          demo_button_text: button_text
          book_time_link
          book_time_link_title
          show_sticky_block
        }

        ... on SearchBlock {
          type
          search_block_title: title
          subtitle
          input_placeholder
          ranking
          background
          background_image_url
          background_image_seo_name
        }

        ... on CourseListBlock {
          type
          background
          items {
            course {
              id
              name
              description
              image_url
              foreground_image_url
              foreground_image_seo_name
              svg_icon
              slug
            }
          }
        }
        ... on ContentItemListBlock {
          type
          background
          heading
          description
          content_items {
            type
            entity {
              ... on Video {
                id
                title
                preview_url
                duration
                description
                verse_video_id
                video_url
                difficulty {
                  id
                  title
                  icon_url
                }
                user {
                  id
                  name
                }
                categories {
                  title
                }
                show_bumper
              }
              ... on Pathway {
                id
                name
                image_url
                videos {
                  id
                  duration
                }
                duration
                count_videos
                slug
                categories {
                  id
                  title
                  parent {
                    id
                    title
                  }
                }
              }
            }
          }
        }
        ... on LetUsTalk {
          type
          background
          title
          subtitle
          button_text
          preview_image_url
          seo_image_name
          book_time_link
          book_time_link_title
          is_form_extended
          usercom_form_id
          form_title_automation
        }
        ... on RequestCourseListBlock {
          type
          background
          title
          button_text
          image_url
          seo_image_name
          user_com_formId
          form_title_automation
        }
      }
    }
  }
`;
