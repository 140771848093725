import { useMutation } from '@apollo/client';

import { useAlert } from '../context/AlertProvider';
import { ADD_VIDEO_TO_WATCHLIST, REMOVE_VIDEO_FROM_WATCHLIST } from '../graphql/videos';
import useTenantTranslation from '../hooks/useTenantTranslation';
import {
  Mutation,
  MutationAddVideoInWatchListArgs,
  MutationDeleteVideoFromWatchListArgs,
} from '../types';

const useToggleFromWatchlist = (existInWatchlist, videoId) => {
  const { showAlert } = useAlert();
  const { t } = useTenantTranslation();

  const [addToWatchList, { loading: addToWatchListLoading }] = useMutation<
    Pick<Mutation, 'AddVideoInWatchList'>,
    MutationAddVideoInWatchListArgs
  >(ADD_VIDEO_TO_WATCHLIST, {
    variables: {
      video_id: videoId,
    },
    refetchQueries: ['GetVideosExistInUserWatchList', 'GetWatchlist'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      showAlert({ message: t('alert.videoAddedToWatchList') });
    },
  });

  const [removeFromWatchList, { loading: removeFromWatchListLoading }] = useMutation<
    Pick<Mutation, 'DeleteVideoFromWatchList'>,
    MutationDeleteVideoFromWatchListArgs
  >(REMOVE_VIDEO_FROM_WATCHLIST, {
    variables: {
      video_id: videoId,
    },
    refetchQueries: ['GetVideosExistInUserWatchList', 'GetWatchlist'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      showAlert({ message: t('alert.videoRemovedFromWatchList') });
    },
  });

  return [
    !existInWatchlist ? addToWatchList : removeFromWatchList,
    addToWatchListLoading || removeFromWatchListLoading,
  ] as [() => void, boolean];
};

export default useToggleFromWatchlist;
