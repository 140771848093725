import React, { useContext } from 'react';

import { Theme } from '@mui/material';

interface ISsrContext {
  redirect?: (url: string) => void;
  theme: null | Theme;
}

const SsrContext = React.createContext<ISsrContext>({
  redirect: undefined,
  theme: null,
});

export const useSsrContext = () => useContext(SsrContext);

export default SsrContext;
