import React from 'react';

import { alpha } from '@mui/material/styles';

import AddToList from '../../assets/icons/AddToList';
import RemoveFromList from '../../assets/icons/RemoveFromList';
import useTenantTranslation from '../../hooks/useTenantTranslation';
import styled from '../../styled';
import { LightTooltip } from '../UI/LightTooltip';

const ToggleFromList = ({
  isAdd = true,
  onClick,
  noBackground = false,
  disabled = false,
  placement = 'top',
}) => {
  const { t } = useTenantTranslation();

  return (
    <LightTooltip
      placement={placement}
      // @ts-ignore
      title={isAdd ? t('common.addToWatchlist') : t('common.removeFromWatchlist')}
    >
      <Wrapper onClick={onClick} noBackground={noBackground} disabled={disabled}>
        {isAdd ? (
          <StyledAddIcon data-testid="addToWatchlistIcon" />
        ) : (
          <StyledRemoveIcon data-testid="removeFromWatchlistIcon" />
        )}
      </Wrapper>
    </LightTooltip>
  );
};

export default ToggleFromList;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'noBackground' && prop !== 'disabled',
})`
  width: ${({ noBackground }) => (noBackground ? 'initial' : '40px')};
  height: ${({ noBackground }) => (noBackground ? 'initial' : '40px')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${({ noBackground, theme }) =>
    noBackground ? 'initial' : theme.palette.common.card.background.light};
  box-shadow: 0px 2px 6px 0px ${({ theme }) => alpha(theme.palette.common.secondaryShadow, 0.3)};
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  transition: opacity 0.6s;
`;
const createIcon = (tag) => styled(tag)`
  position: relative;
  top: 2px;
  left: 2px;
`;
const StyledAddIcon = createIcon(AddToList);
const StyledRemoveIcon = createIcon(RemoveFromList);
