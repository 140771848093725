import { PaletteMode } from '@mui/material';

import { getHeroImageSet } from './tenantsConfig';

const generateRandomImageUrl = (mode: PaletteMode, tenantName?: string | null) => {
  if (process.env.REACT_APP_IS_E2E) {
    return getHeroImageSet(mode).financeunlocked.at(1);
  }

  if (tenantName && getHeroImageSet(mode)?.[tenantName]) {
    const imageIndex = Math.floor(Math.random() * getHeroImageSet(mode)[tenantName].length);
    return getHeroImageSet(mode)[tenantName][imageIndex];
  } else {
    const imageIndex = Math.floor(Math.random() * getHeroImageSet(mode)['financeunlocked'].length);
    return getHeroImageSet(mode)['financeunlocked'][imageIndex];
  }
};

export default generateRandomImageUrl;
