import React, { FC } from 'react';

import { useTheme } from '@mui/material';

import DownloadIcon from '../../../../assets/icons/DownloadIcon';
import LinkedInIcon from '../../../../assets/icons/LinkedInIcon';
import ShareIcon from '../../../../assets/icons/ShareIcon';
import useIsSsku from '../../../../hooks/tenant/useIsSsku';
import useTenantTranslation from '../../../../hooks/useTenantTranslation';
import Modal, { Modals } from '../../../../modals/Modal';
import styled from '../../../../styled';
import { SMALL_MARGIN_PX } from '../../../../theme';
import { LinkedInModalProps } from '../../../LinkedInModal/LinkedInModal';
import { ShareModalProps } from '../../../ShareModal/ShareModal';
import { LightTooltip } from '../../../UI/LightTooltip';

interface CardTestResultsProps {
  linkedinModalProps: LinkedInModalProps;
  shareModalProps: ShareModalProps;
  handleCertDownload?: () => void;
  certDownloading?: boolean;
}

const CardTestResults: FC<CardTestResultsProps> = ({
  linkedinModalProps,
  shareModalProps,
  handleCertDownload,
  certDownloading,
}) => {
  const { t } = useTenantTranslation();
  const isSsku = useIsSsku();
  const theme = useTheme();

  return (
    <Wrapper data-testid="cardTestResults">
      <BtnWrapper>
        <Modal {...linkedinModalProps}>
          {({ openModal }) => (
            <StyledTooltip title={t('page.pathway.addToProfile') || ''} placement="top">
              <Btn data-testid="addToProfileBtn" onClick={() => openModal(Modals.LinkedInModal)}>
                <StyledLinkedInIcon color={theme.palette.common.white} />
                <BtnText>{t('page.pathway.addToProfile')}</BtnText>
              </Btn>
            </StyledTooltip>
          )}
        </Modal>
      </BtnWrapper>
      {handleCertDownload && (
        <BtnWrapper>
          <StyledTooltip
            title={isSsku ? t('common.certificate') : t('page.pathway.cpdCertificate')}
            placement="top"
          >
            <DownloadButton
              data-testid="downloadCertificateBtn"
              onClick={handleCertDownload}
              disabled={certDownloading}
            >
              <StyledDownloadIcon color={theme.palette.common.white} />
              <BtnText>
                {isSsku ? t('common.certificate') : t('page.pathway.cpdCertificate')}
              </BtnText>
            </DownloadButton>
          </StyledTooltip>
        </BtnWrapper>
      )}
      <BtnWrapper>
        <Modal {...shareModalProps}>
          {({ openModal }) => (
            <StyledTooltip title={t('page.pathway.share') || ''} placement="top">
              <Btn data-testid="openShareModalBtn" onClick={() => openModal(Modals.ShareModal)}>
                <StyledShareIcon color={theme.palette.common.white} />
                <BtnText>{t('page.pathway.share')}</BtnText>
              </Btn>
            </StyledTooltip>
          )}
        </Modal>
      </BtnWrapper>
    </Wrapper>
  );
};

export default CardTestResults;

const Btn = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 24px;
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.palette.common.white};
  line-height: 1;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
const DownloadButton = styled(Btn)`
  transition: opacity 0.3s;
  opacity: ${({ disabled }) => disabled && 0.3};
  pointer-events: ${({ disabled }) => disabled && 'none'};
`;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: ${SMALL_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 0;
    flex-direction: row;
  }
`;
const BtnWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 47px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    &:not(:last-child) {
      padding-right: ${SMALL_MARGIN_PX};
      margin-right: ${SMALL_MARGIN_PX};
    }
  }
`;
const BtnText = styled('span')`
  display: block;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    display: block;
  }
`;
const createStyledIcon = (tag) => styled(tag)`
  width: 21px;
  height: 21px;
  margin-right: 9px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-right: 0;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    margin-right: 9px;
  }
`;
const StyledLinkedInIcon = createStyledIcon(LinkedInIcon);
const StyledDownloadIcon = createStyledIcon(DownloadIcon);
const StyledShareIcon = createStyledIcon(ShareIcon);
const StyledTooltip = styled(LightTooltip)`
  ${({ theme }) => theme.breakpoints.up('xl')} {
    display: none;
  }
`;
