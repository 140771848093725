import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Box, Container } from '@mui/material';
import { alpha } from '@mui/material/styles';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { DEFAULT_DISCOVER_URL } from '../../const';
import useIsSsku from '../../hooks/tenant/useIsSsku';
import useEventListener from '../../hooks/useEventListener';
import useRestrictedContainer from '../../hooks/useRestrictedContainer';
import useWatchList from '../../hooks/useWatchList';
import styled from '../../styled';
import { BREAKPOINTS_VALUES, MIDDLE_MARGIN_PX, SMALL_MARGIN_PX, useIsWidthUp } from '../../theme';
import { Tenant } from '../../types';
import { splitIntoChunks } from '../../utils/arrayUtils/splitIntoChunks';
import { secondsToHHHmmm } from '../../utils/dateUtils/secondsToHHHmmm';
import { secondsToHms } from '../../utils/dateUtils/secondsToHms';
import AnimatedSkeleton from '../AnimatedSkeleton';
import { Pathway } from '../Pathway';
import { BodyText, H2 } from '../UI/Texts';
import { Video } from '../Video';

type SliderBreakpoint = {
  slidesPerView?: number;
  slidesOffsetAfter?: number;
};

const getDefaultBreakpoints = (
  isPortraitOrientation: boolean,
  withChunksOnMobile: boolean,
): { [p: string]: SliderBreakpoint } => ({
  [BREAKPOINTS_VALUES.xl]: {
    slidesPerView: isPortraitOrientation ? 6 : 5.2,
  },
  [BREAKPOINTS_VALUES.lg]: {
    slidesPerView: isPortraitOrientation ? 5 : 4.2,
  },
  [BREAKPOINTS_VALUES.md]: {
    slidesPerView: isPortraitOrientation ? 4 : 3.2,
  },
  [BREAKPOINTS_VALUES.sm]: {
    slidesPerView: isPortraitOrientation ? 3 : 2.2,
    slidesOffsetAfter: isPortraitOrientation ? 0 : 16,
  },
  [BREAKPOINTS_VALUES.xs]: {
    slidesPerView: isPortraitOrientation ? 2.5 : withChunksOnMobile ? 1 : 1.2,
    slidesOffsetAfter: isPortraitOrientation || withChunksOnMobile ? 0 : 16,
  },
});

interface VideosBlockProps {
  data?: any;
  tenant?: Tenant | null;
  getBreakpoints?: (isPortraitOrientation: boolean) => { [p: string]: SliderBreakpoint };
  handleClick?: (id: string) => void;
  withArrows?: boolean;
  className?: string;
  titleToLeft?: boolean;
  loading?: boolean;
  wrapperId?: string;
  withChunksOnMobile?: boolean;
}

const loadingItems = new Array(6).fill(undefined);

const VideosBlock: React.FC<VideosBlockProps> = ({
  data,
  getBreakpoints = getDefaultBreakpoints,
  className,
  withArrows = true,
  tenant,
  titleToLeft = false,
  loading,
  wrapperId,
  withChunksOnMobile = false,
}) => {
  const { background, orientation, heading, description } = data;
  const RestrictedContainer = useRestrictedContainer();
  const isMobile = !useIsWidthUp('sm');
  const isSsku = useIsSsku();

  const videosIds = data?.items
    ?.filter((item) => item.type !== 'pathway')
    ?.map((item) => item?.video?.id);

  const chunks = useMemo(() => {
    return isMobile && withChunksOnMobile ? splitIntoChunks(data?.items, 2) : data?.items;
  }, [isMobile, withChunksOnMobile, data]);

  const watchlistById = useWatchList(videosIds);

  const isPortraitOrientation = orientation === 'Portrait';
  const [videoWidth, setVideoWidth] = useState<number | null>(null);
  const [hasMaxWidth, setHasMaxWidth] = useState(true);
  const videoWrapRef = useRef(null);
  const widthTimeout = useRef<null | ReturnType<typeof setTimeout>>(null);

  const setVideoWidthHandler = () => {
    //add timeout, because gets wrong value after resize
    widthTimeout.current = setTimeout(() => {
      // @ts-ignore
      const videoWidthValue = videoWrapRef?.current?.clientWidth;
      setVideoWidth(videoWidthValue);
    }, 500);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasMaxWidth(false);
    }, 0);
    setVideoWidthHandler();

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    return () => {
      widthTimeout?.current && clearTimeout(widthTimeout.current);
    };
  }, []);

  useEventListener('resize', setVideoWidthHandler);

  const videosOnDesktopCount = isPortraitOrientation ? 2.5 : 1.2;

  const dynamicSlidesOffsetAfter = videoWidth ? videoWidth * 0.2 + 50 : 100;
  const breakpoints = getBreakpoints(isPortraitOrientation, withChunksOnMobile);

  const swiperSettings: SwiperOptions = {
    slidesPerView: videosOnDesktopCount,
    spaceBetween: 16,
    pagination: {
      clickable: true,
    },
    mousewheel: {
      releaseOnEdges: true,
      forceToAxis: true,
      invert: true,
    },
    navigation: withArrows,
    observer: true,
    slidesOffsetAfter: isPortraitOrientation ? 0 : dynamicSlidesOffsetAfter,
    breakpoints,
  };

  if (loading) {
    return (
      <Wrapper
        id={wrapperId}
        background={background}
        isPortraitOrientation={isPortraitOrientation}
        className={cn(background === 'Dark' ? 'dark-block' : 'light-block', className)}
        withChunksOnMobile={withChunksOnMobile}
      >
        <StyledContainer
          className="landscape-container"
          maxWidth={isPortraitOrientation ? undefined : 'ml'}
        >
          {heading && (
            <StyledRestrictedContainer as={RestrictedContainer}>
              <LoadingHeading sm="35px" />
            </StyledRestrictedContainer>
          )}
          <StyledSwiper
            isPortraitOrientation={isPortraitOrientation}
            videoWidth={videoWidth}
            hasMaxWidth={hasMaxWidth}
            {...swiperSettings}
          >
            {loadingItems.map((item, index) => (
              <SwiperSlide key={index}>
                {isMobile && withChunksOnMobile ? (
                  <ChunkWrapper>
                    <LoadingCard />
                    <LoadingCard />
                  </ChunkWrapper>
                ) : (
                  <SlideWrapper>
                    <LoadingCard />
                  </SlideWrapper>
                )}
              </SwiperSlide>
            ))}
          </StyledSwiper>
        </StyledContainer>
      </Wrapper>
    );
  }

  if (!data?.items || data?.items?.length === 0) {
    return null;
  }

  return (
    <Wrapper
      id={wrapperId}
      background={background}
      isPortraitOrientation={isPortraitOrientation}
      className={cn(background === 'Dark' ? 'dark-block' : 'light-block', className)}
      withChunksOnMobile={withChunksOnMobile}
      data-testid="videosBlock"
    >
      <StyledContainer
        className="landscape-container"
        maxWidth={isPortraitOrientation ? undefined : 'ml'}
      >
        {(heading || description) && (
          <StyledRestrictedContainer as={RestrictedContainer}>
            <Heading
              special={isSsku}
              variant="h2"
              component="h2"
              align={titleToLeft ? 'left' : 'center'}
            >
              {heading}
            </Heading>
            {description && (
              <Description align={titleToLeft ? 'left' : 'center'}>{description}</Description>
            )}
          </StyledRestrictedContainer>
        )}

        <StyledSwiper
          isPortraitOrientation={isPortraitOrientation}
          videoWidth={videoWidth}
          hasMaxWidth={hasMaxWidth}
          {...swiperSettings}
        >
          {chunks &&
            chunks.map((item, index) => {
              if (isMobile && withChunksOnMobile) {
                return (
                  <SwiperSlide key={index}>
                    <ChunkWrapper>
                      {item &&
                        item.map((chunkItem, index) => {
                          const type = chunkItem?.type;
                          if (type === 'pathway') {
                            return (
                              <Pathway
                                key={index}
                                videoWidth={videoWidth}
                                id={chunkItem?.pathway?.id}
                                name={chunkItem?.pathway?.name}
                                description={chunkItem?.pathway?.description}
                                imageUrl={chunkItem?.pathway?.image_url}
                                duration={secondsToHHHmmm(chunkItem?.pathway?.duration)}
                                countVideos={chunkItem?.pathway?.count_videos}
                                slug={chunkItem?.pathway?.slug}
                                background={background}
                                progress={chunkItem?.pathway?.progress}
                              />
                            );
                          } else {
                            const id = chunkItem?.video?.id;
                            const expertLink = chunkItem?.video?.user?.slug
                              ? `${DEFAULT_DISCOVER_URL}/experts/${chunkItem?.video?.user?.slug}`
                              : null;

                            const defaultLink = `/videos/${
                              item.video?.video_url || item.video?.id
                            }/`;

                            return (
                              <Video
                                key={index}
                                id={chunkItem?.video?.id}
                                title={chunkItem?.video?.title}
                                duration={secondsToHms(chunkItem?.video?.duration)}
                                userName={chunkItem?.video?.user?.name}
                                categories={chunkItem?.video?.categories}
                                difficulty={chunkItem?.video?.difficulty}
                                previewUrl={chunkItem?.video?.preview_url}
                                description={chunkItem?.video?.description}
                                orientation={orientation}
                                videoWidth={videoWidth}
                                background={background}
                                progress={chunkItem?.video?.progress}
                                handleClick={chunkItem?.video?.handleClick}
                                durationNumb={chunkItem?.video?.duration}
                                link={chunkItem?.video?.link || defaultLink}
                                expertLink={expertLink}
                                verseId={chunkItem?.video?.verse_video_id}
                                bumperUrl={
                                  chunkItem?.video?.show_bumper ? tenant?.video_bumper_url : null
                                }
                                isInWatchlist={watchlistById[id]}
                                shouldOpenInModal={!!data?.show_on_popups}
                              />
                            );
                          }
                        })}
                    </ChunkWrapper>
                  </SwiperSlide>
                );
              } else {
                const type = item?.type;
                if (type === 'pathway') {
                  const id = item?.pathway?.id;
                  const name = item?.pathway?.name;
                  const description = item?.pathway?.description;
                  const imageUrl = item?.pathway?.image_url;
                  const duration = secondsToHHHmmm(item?.pathway?.duration);
                  const countVideos = item?.pathway?.count_videos;
                  const slug = item?.pathway?.slug;
                  const progress = item?.pathway?.progress;
                  return (
                    <SwiperSlide key={index} className="pathwaySlide">
                      <SlideWrapper ref={videoWrapRef}>
                        <Pathway
                          videoWidth={videoWidth}
                          id={id}
                          name={name}
                          description={description}
                          imageUrl={imageUrl}
                          duration={duration}
                          countVideos={countVideos}
                          slug={slug}
                          background={background}
                          progress={progress}
                        />
                      </SlideWrapper>
                    </SwiperSlide>
                  );
                } else {
                  const id = item?.video?.id;
                  const title = item?.video?.title;
                  const userName = item?.video?.user?.name;
                  const difficulty = item?.video?.difficulty;
                  const categories = item?.video?.categories;
                  const previewUrl = item?.video?.preview_url;
                  const description = item?.video?.description;
                  const duration = secondsToHms(item?.video?.duration);
                  const progress = item?.video?.progress;
                  const handleClick = item?.video?.handleClick;
                  const link = item?.video?.link;
                  const verseId = item?.video?.verse_video_id;
                  const expertLink = item?.video?.user?.slug
                    ? `${DEFAULT_DISCOVER_URL}/experts/${item?.video?.user?.slug}`
                    : null;

                  const defaultLink = `/videos/${item.video?.video_url || item.video?.id}`;

                  return (
                    <SwiperSlide key={index}>
                      <SlideWrapper ref={videoWrapRef}>
                        <Video
                          id={id}
                          title={title}
                          duration={duration}
                          userName={userName}
                          categories={categories}
                          difficulty={difficulty}
                          previewUrl={previewUrl}
                          description={description}
                          orientation={orientation}
                          videoWidth={videoWidth}
                          background={background}
                          progress={progress}
                          handleClick={handleClick}
                          durationNumb={item?.video?.duration}
                          link={link || defaultLink}
                          expertLink={expertLink}
                          verseId={verseId}
                          bumperUrl={item?.video?.show_bumper ? tenant?.video_bumper_url : null}
                          isInWatchlist={watchlistById[id]}
                          shouldOpenInModal={!!data?.show_on_popups}
                        />
                      </SlideWrapper>
                    </SwiperSlide>
                  );
                }
              }
            })}
        </StyledSwiper>
      </StyledContainer>
    </Wrapper>
  );
};

export default VideosBlock;

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isPortraitOrientation' && prop !== 'background' && prop !== 'withChunksOnMobile',
})`
  padding-top: 36px;
  padding-bottom: 36px;
  background: ${({ theme }) => theme.palette.common.blockBackground.main};

  &:first-child {
    margin-top: 76px;
  }

  & .swiper::after {
    content: '';
    display: ${({ withChunksOnMobile }) => (withChunksOnMobile ? 'none' : 'block')};
    position: absolute;
    ${({ isPortraitOrientation }) => (isPortraitOrientation ? 'top: 0' : 'bottom: 0')};
    right: -1px;
    width: 50px;
    height: ${({ isPortraitOrientation }) => (isPortraitOrientation ? '225px' : '100%')};
    background: ${({ background, theme }) =>
      background === 'Dark'
        ? ` transparent linear-gradient(-90deg, ${
            theme.palette.common.blockBackground.main
          } 0%, ${alpha(
            theme.palette.common.blockBackground.main,
            0,
          )} 100%) 0% 0% no-repeat padding-box`
        : ` transparent linear-gradient(-90deg, ${
            theme.palette.common.blockBackground.light
          } 0%, ${alpha(
            theme.palette.common.blockBackground.light,
            0,
          )} 100%) 0% 0% no-repeat padding-box`};
    z-index: 3;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: 72px;
    padding-bottom: 72px;
    background: ${({ background, theme }) =>
      background === 'Dark'
        ? theme.palette.common.blockBackground.main
        : theme.palette.common.blockBackground.light};

    &:first-child {
      margin-top: 120px;
    }

    & .swiper::after {
      display: block;
      height: 100%;
      opacity: ${({ isPortraitOrientation }) => (isPortraitOrientation ? 0 : 1)};
    }
  }
`;
const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'isPortraitOrientation',
})`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-right: ${({ isPortraitOrientation }) => (isPortraitOrientation ? 16 : 0)}px;
  }
`;
const LoadingCard = styled(AnimatedSkeleton)`
  height: 260px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 100%;
  }
`;
const LoadingHeading = styled(AnimatedSkeleton)`
  max-width: 450px;
  margin-bottom: ${MIDDLE_MARGIN_PX};
  margin-right: auto;
`;

const ChunkWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 496px;

  & > *:first-child {
    margin-bottom: ${SMALL_MARGIN_PX};
  }
`;
const Description = styled(BodyText)`
  margin-bottom: ${MIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: ${({ align }) => (align === 'center' ? '70%' : '100%')};
    margin: ${({ align }) => (align === 'center' ? '0 auto' : '0')};
    margin-bottom: ${MIDDLE_MARGIN_PX};
  }
`;

const StyledRestrictedContainer = styled('div')`
  & {
    padding: 0 16px 0 0;
  }
`;
const Heading = styled(H2, {
  shouldForwardProp: (prop) => prop !== 'additionalStyles',
})`
  && {
    margin: auto;
    margin-bottom: 22px;

    &:first-letter {
      text-transform: uppercase;
    }

    ${({ theme }) => theme.breakpoints.up('sm')} {
      margin-bottom: ${MIDDLE_MARGIN_PX};
    }

    ${({ theme }) => theme.breakpoints.up('ml')} {
      font-size: 40px;
    }
  }
`;
const SlideWrapper = styled('div')`
  height: 100%;
`;
const StyledSwiper = styled(Swiper, {
  shouldForwardProp: (prop) =>
    prop !== 'isPortraitOrientation' && prop !== 'videoWidth' && prop !== 'hasMaxWidth',
})`
  & .swiper-slide {
    overflow: hidden;
    transition: ${({ hasMaxWidth }) => (hasMaxWidth ? 'none' : 'height .3s, width .3s')};
    max-width: ${({ hasMaxWidth }) => (hasMaxWidth ? '20%' : 'none')};
    margin-right: ${({ hasMaxWidth }) => (hasMaxWidth ? '2%' : 'none')};

    &.pathwaySlide {
      padding: 20px 0 0 0;

      & > div {
        margin-right: 24px;
      }
    }

    ${({ theme }) => theme.breakpoints.up('md')} {
      height: 316px;

      &:hover {
        height: ${({ isPortraitOrientation }) => !isPortraitOrientation && '100%'};
        width: ${({ isPortraitOrientation, videoWidth }) =>
          !isPortraitOrientation && `${videoWidth + videoWidth * 0.2}px !important`};
      }
    }
  }
`;
