import React, { useEffect, useCallback, FC, useContext } from 'react';

import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { captureException } from '@sentry/react';

import PrivacyBlock from '../../blocks/PrivacyBlock';
import { UserInfoCtx } from '../../containers/CommonLayout/CommonLayout';
import { useAlert } from '../../context/AlertProvider';
import { TenantCtx } from '../../context/TenantProvider';
import useTenantTranslation from '../../hooks/useTenantTranslation';
import useValidation from '../../hooks/useValidation';
import styled from '../../styled';
import { MIDDLE_MARGIN_PX, SMALL_MARGIN_PX, SUBMIDDLE_MARGIN_PX } from '../../theme';
import { ContactFormItem } from '../../types';
import UserCom from '../../utils/UserCom';
import { sendDataToGTM } from '../../utils/gtmUtils';
import sha256 from '../../utils/sha256';
import { splitName } from '../../utils/userComUtils';
import Checkbox from '../Checkbox/Checkbox';
import { CustomButton } from '../UI/CustomButton';
import { contactFormSchema } from './validation';

const triggerSubmitEvent = () => {
  if (!document) return;

  let event;
  if (document.createEvent) {
    event = document.createEvent('HTMLEvents');
    event.initEvent('submit', true, true);
    event.eventName = 'submit';
    document.dispatchEvent(event);
  } else {
    // @ts-ignore
    event = document.createEventObject();
    event.eventName = 'submit';
    event.eventType = 'submit';
    // @ts-ignore
    document.fireEvent('on' + event.eventType, event);
  }
};

const initialValues = {
  name: '',
  email: '',
  reason: null,
  message: '',
  newsletterPositiveConsent: true,
};

interface ContactFormProps {
  reasonItems: ContactFormItem[];
  userName?: string | null;
  userEmail?: string | null;
  userComFormId?: string | null;
  nameField?: string | null;
  emailField?: string | null;
  contactReasonField?: string | null;
  contactMessageField?: string | null;
}

export const ContactForm: FC<ContactFormProps> = ({
  reasonItems,
  userName,
  userEmail,
  userComFormId,
  nameField,
  emailField,
  contactReasonField,
  contactMessageField,
}) => {
  const { t } = useTenantTranslation();
  const { tenant } = useContext(TenantCtx);
  const { showAlert } = useAlert();
  const { userInfo } = useContext(UserInfoCtx);

  const onSubmit = useCallback(
    async (values, { setValuesManually }) => {
      if (UserCom.checkHasUserCom()) {
        try {
          const event = values?.reason?.is_sales_request
            ? 'form_conversion'
            : 'contact_form_submitted';

          const [firstName, lastName] = splitName(values.name);

          const data = {
            ...(emailField && { [emailField]: values.email }),
            ...(contactReasonField && { [contactReasonField]: values.reason?.type }),
            ...(contactMessageField && {
              [contactMessageField]: {
                message: values.message.trim(),
              },
            }),
            ...(!userInfo && { newsletter_positive_consent: values.newsletterPositiveConsent }),
            request_form_title_automation: 'Contact us',
            form_tenant: tenant?.name || '',
          };

          //used updateClient instead of pageHit, because it returns promise
          await UserCom.updateClient({
            //name attribute does not update user name in user.com,just save it as custom attribute
            first_name: firstName,
            last_name: lastName,
            ...data,
          });

          //fill "formEmail" dataLayer field with hashed email for conversion tracking
          const hashedEmail = await sha256(values.email);

          sendDataToGTM({
            formEmail: hashedEmail,
          });

          await UserCom.sendEvent(event, {
            form_id: userComFormId,
            ...(nameField && { [nameField]: values.name }),
            ...data,
          });

          showAlert({ message: t('alert.messageSendSuccessfully') });
          triggerSubmitEvent();

          setValuesManually({
            ...initialValues,
            name: userName || '',
            email: userEmail || '',
          });
        } catch (e) {
          captureException(e);
        }
      }
    },
    [
      showAlert,
      userName,
      userEmail,
      userComFormId,
      nameField,
      emailField,
      contactReasonField,
      contactMessageField,
      tenant,
      t,
      userInfo,
    ],
  );

  const { values, errors, handleChange, setFieldManually, handleSubmit } = useValidation(
    initialValues,
    contactFormSchema,
    onSubmit,
  );

  useEffect(() => {
    if (userName) {
      setFieldManually('name', userName);
    }
  }, [userName, setFieldManually]);

  useEffect(() => {
    if (userEmail) {
      setFieldManually('email', userEmail);
    }
  }, [userEmail, setFieldManually]);

  return (
    <>
      <StyledForm
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          //prevent submit event for validation
          e.stopPropagation();
          handleSubmit();
        }}
      >
        <ContactFormFieldWrap>
          <ContactFormField
            inputProps={{ 'data-testid': 'nameField' }}
            name="name"
            label={t('input.placeholder.yourName')}
            variant="outlined"
            value={values.name}
            onChange={handleChange}
            error={Boolean(errors?.name)}
            helperText={errors?.name}
          />
        </ContactFormFieldWrap>
        <ContactFormFieldWrap>
          <ContactFormField
            inputProps={{ 'data-testid': 'emailField' }}
            name="email"
            label={t('input.placeholder.yourEmail')}
            variant="outlined"
            value={values.email}
            onChange={handleChange}
            error={Boolean(errors?.email)}
            helperText={errors?.email}
          />
        </ContactFormFieldWrap>
        <ContactFormFieldWrap>
          <Autocomplete
            data-testid="departmentField"
            options={reasonItems}
            getOptionLabel={(option) => option?.question || ''}
            value={values.reason}
            onChange={(event, value) => {
              setFieldManually('reason', value || null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="reason"
                label={t('input.placeholder.howCanWeHelp')}
                variant="outlined"
                error={Boolean(errors?.reason)}
                helperText={errors?.reason}
              />
            )}
          />
        </ContactFormFieldWrap>

        <ContactFormField
          inputProps={{
            'data-testid': 'messageField',
            'aria-invalid': errors.message ? true : undefined,
          }}
          name="message"
          label={t('input.placeholder.message')}
          variant="outlined"
          multiline
          rows={9}
          value={values.message}
          onChange={handleChange}
          error={Boolean(errors?.message)}
          helperText={errors?.message}
        />

        {!userInfo && (
          <StyledCheckbox
            name="newsletterPositiveConsent"
            onChange={() =>
              setFieldManually('newsletterPositiveConsent', !values?.newsletterPositiveConsent)
            }
            checked={values?.newsletterPositiveConsent}
          >
            {t('block.letsTalk.newsletterAgreement')}
          </StyledCheckbox>
        )}

        <StyledCustomButton type="submit" data-testid="submit">
          {t('button.submit')}
        </StyledCustomButton>
        <StyledPrivacyBlock />
      </StyledForm>
    </>
  );
};

const StyledForm = styled('form')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    align-items: flex-start;
  }
`;
const ContactFormFieldWrap = styled('div')`
  margin-bottom: ${SMALL_MARGIN_PX};
  width: 100%;
`;
const ContactFormField = styled(TextField)`
  width: 100%;
`;
const StyledCustomButton = styled(CustomButton)`
  margin-top: ${MIDDLE_MARGIN_PX};
`;
const StyledCheckbox = styled(Checkbox)`
  margin-top: ${SMALL_MARGIN_PX};
`;
const StyledPrivacyBlock = styled(PrivacyBlock)`
  margin-top: ${SUBMIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: ${MIDDLE_MARGIN_PX};
  }
`;
