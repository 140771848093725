import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { GlobalStyles, PaletteMode } from '@mui/material';
import {
  StyledEngineProvider,
  Theme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';

import { MODE_COOKIE_KEY } from '../const';
import useIsSsku from '../hooks/tenant/useIsSsku';
import useIsDeviceInPortraitMode from '../hooks/useIsDeviceInPortraitMode';
import globalStyles from '../styles';
import { defaultTenantTheme, santanderTheme, tenantThemes } from '../theme';
import { Tenant } from '../types';
import { checkConsent } from '../utils/checkConsent';
import Cookies from '../utils/cookies';
import { TenantsRefs } from '../utils/tenantsConfig';
import SsrContext from './SsrContext';

const StyledEngineProviderOnlyForDevMode = ({ children }) =>
  process.env.NODE_ENV === 'development' ? (
    <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
  ) : (
    children
  );

const noOp = () => {};
export const ThemeCtx = createContext<{
  isDeviceInPortraitMode: boolean;
  mode: PaletteMode;
  setThemeByTenant: (tenantName: Tenant['name']) => void;
  setTheme: Dispatch<SetStateAction<Theme>>;
  toggleColorMode: () => void;
  setMode: Dispatch<SetStateAction<PaletteMode>>;
}>({
  isDeviceInPortraitMode: false,
  mode: 'dark',
  setThemeByTenant: noOp,
  setTheme: noOp,
  toggleColorMode: noOp,
  setMode: noOp,
});

export const mapTenantToTheme = (tenantName, mode = 'dark') => {
  switch (tenantName) {
    case TenantsRefs.Ssku:
      return santanderTheme[mode];
    default:
      return defaultTenantTheme[mode];
  }
};

export const getThemeByQueryParams = (url: string, mode = 'dark'): Theme | null => {
  if (!url) {
    return null;
  }

  const searchParams = new URLSearchParams(url);
  const themeIndex = searchParams.get('themeIndex');
  const modeFromParams = searchParams.get('mode');

  if (themeIndex) {
    return tenantThemes[+themeIndex][modeFromParams || mode];
  }

  return null;
};

interface IThemeProvider {
  children: ReactNode;
  initialTheme?: Theme;
  hasGlobalStyles?: boolean;
}

const ThemeProvider: FC<IThemeProvider> = ({ children, initialTheme, hasGlobalStyles = true }) => {
  const { theme: themeFromSsr } = useContext(SsrContext);
  const isSsku = useIsSsku();
  const [mode, setMode] = React.useState<PaletteMode>(
    isSsku ? Cookies.get(MODE_COOKIE_KEY) || 'dark' : 'dark',
  );
  const [selectedTheme, setSelectedTheme] = useState<Theme>(
    themeFromSsr || initialTheme || defaultTenantTheme[mode],
  );
  const isDeviceInPortraitMode = useIsDeviceInPortraitMode();

  const memoizedValue = useMemo(
    () => ({
      isDeviceInPortraitMode,
      mode,
      setThemeByTenant: (tenantName) => setSelectedTheme(mapTenantToTheme(tenantName, mode)),
      setTheme: setSelectedTheme,
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
      setMode,
    }),
    [setSelectedTheme, setMode, mode, isDeviceInPortraitMode],
  );

  useEffect(() => {
    checkConsent(MODE_COOKIE_KEY) && Cookies.set(MODE_COOKIE_KEY, mode);
  }, [mode]);

  const gs = useMemo(() => globalStyles(selectedTheme), [selectedTheme]);

  return (
    <StyledEngineProviderOnlyForDevMode>
      <MuiThemeProvider theme={selectedTheme}>
        <ThemeCtx.Provider value={memoizedValue}>
          {hasGlobalStyles && <GlobalStyles styles={gs} />}
          {children}
        </ThemeCtx.Provider>
      </MuiThemeProvider>
    </StyledEngineProviderOnlyForDevMode>
  );
};

export default ThemeProvider;
