import React, { FC } from 'react';

import { styled } from '@mui/material/styles';

import UserLearningDueDate from '../../../../components/UserLearningBlock/components/UserLearningDueDate/UserLearningDueDate';
import { DEFAULT_DISCOVER_URL } from '../../../../const';
import useTenantTranslation from '../../../../hooks/useTenantTranslation';
import { SMALL_MARGIN_PX } from '../../../../theme';
import {
  AnnouncementEntity,
  NotificationForCompletedPathway,
  NotificationType,
} from '../../../../types';
import Notification, { AnnouncementType } from './Notification';

export const isNotificationForCompletedPathway = (
  item: AnnouncementEntity,
): item is NotificationForCompletedPathway => item.__typename === 'NotificationForCompletedPathway';

const NotificationFabric: FC<{ item: AnnouncementEntity; onClick?: () => void }> = ({
  item,
  onClick,
}) => {
  const { t } = useTenantTranslation();

  if (item.__typename === 'NotificationForAssignment') {
    const assignment = item?.assignment;
    const isNew = item.type === NotificationType.New;
    const title = `${t(
      isNew ? 'page.assignedLearnings.newAssignment' : 'common.assignmentReminder',
    )}: ${assignment?.title}`;

    return (
      <StyledNotification
        key={item?.id}
        id={item?.id}
        link={`/assigned-learning#${assignment?.id}`}
        title={title}
        subtitle={<StyledUserLearningDueDate dueDate={assignment?.due_date} />}
        type={item?.type}
        highlighted={item?.is_highlighted}
        onClick={onClick}
      />
    );
  }

  if (item.__typename === 'Pathway') {
    return (
      <StyledNotification
        key={item?.id}
        id={item?.id}
        title={`${t('common.newPathway')}: ${item?.name}`}
        subtitle={[
          `${item?.count_videos} ${t('common.videoModules')}`,
          item?.categories?.[0]?.title,
        ]
          .filter(Boolean)
          .join(' • ')}
        link={`${DEFAULT_DISCOVER_URL}/pathways/${item?.slug}`}
        type={AnnouncementType.Pathway}
        highlighted={item?.is_highlighted}
        onClick={onClick}
      />
    );
  }

  if (item.__typename === 'Video' || item.__typename === 'NewVideoFromExpert') {
    return (
      <StyledNotification
        key={item?.id}
        id={item?.id}
        title={`${t('common.newVideo')}: ${item?.title}`}
        subtitle={[item?.user?.name, item?.categories?.[0]?.title].filter(Boolean).join(' • ')}
        link={`/videos/${item?.video_url}`}
        type={item.__typename === 'Video' ? AnnouncementType.Video : AnnouncementType.FollowedVideo}
        highlighted={item?.is_highlighted}
        onClick={onClick}
      />
    );
  }

  if (item.__typename === 'NotificationForCompletedPathway') {
    return (
      <StyledNotification
        key={item?.pathway?.id}
        id={item?.pathway?.id}
        title={t('common.reviewRecentPathway')}
        subtitle={item?.pathway?.name}
        link={`/discover/pathways/${item?.pathway?.slug}#review-form`}
        type={AnnouncementType.PathwayReview}
        onClick={onClick}
      />
    );
  }

  return null;
};

export default NotificationFabric;

const StyledUserLearningDueDate = styled(UserLearningDueDate)`
  && {
    color: ${({ theme }) => theme.palette.common.text.fadedSubtitle};
    font-size: inherit;
  }
`;
const StyledNotification = styled(Notification)`
  &:not(:last-of-type) {
    margin-bottom: ${SMALL_MARGIN_PX};
  }
`;
