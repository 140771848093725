import React, { lazy, Suspense } from 'react';

import { Container, Typography } from '@mui/material';
import { Routes, Route, useLocation } from 'react-router-dom';

import Hero from '../../components/Hero/Hero';
import { Loader } from '../../components/Loader';
import useTenantTranslation from '../../hooks/useTenantTranslation';
import { MANAGER_AL_ROUTES } from '../../routes';
import styled, { css } from '../../styled';
import { LARGE_MARGIN_PX, MIDDLE_MARGIN_PX, SMALL_MARGIN_PX } from '../../theme';
import { LearnerInfo } from '../../types';

const VideoAssignmentTest = lazy(() => import('./pages/VideoAssignmentTest/VideoAssignmentTest'));
const Manager = lazy(() => import('./pages/Manager/Manager'));
const User = lazy(() => import('./pages/User/User'));

interface AssignedLearningPageProps {
  userInfo?: LearnerInfo;
  title?: string;
  tenantTitle?: string | null;
  tenantName?: string | null;
}

const AssignedLearningPage: React.FC<AssignedLearningPageProps> = ({
  userInfo,
  title,
  tenantTitle,
  tenantName,
}) => {
  const { t } = useTenantTranslation();

  const location = useLocation();
  const isManagerDashboard = location?.pathname.includes(MANAGER_AL_ROUTES.List);

  const assignedTitle = title || t('page.assignedLearnings.assigned');

  return (
    <div>
      <Routes>
        <Route
          path=":id/test"
          element={
            <Suspense>
              <VideoAssignmentTest />
            </Suspense>
          }
        />
        <Route
          path="/*"
          element={
            <>
              <Hero
                title={`${assignedTitle} ${t('page.assignedLearnings.learning')}`}
                wrapperCss={headerWrapperCss}
                contentWrapperCss={headerContentWrapperCss}
                titleCss={headerTitleCss}
                tenantName={tenantName}
              >
                <Container>
                  <HeaderDescription>
                    {t(
                      isManagerDashboard
                        ? 'page.assignedLearnings.learningManagerDesc'
                        : 'page.assignedLearnings.learningLearnerDesc',
                    )}
                  </HeaderDescription>
                </Container>
              </Hero>
              <Routes>
                {userInfo?.is_manager && (
                  <Route
                    path="/dashboard/*"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Manager tenantTitle={tenantTitle} />
                      </Suspense>
                    }
                  />
                )}
                <Route
                  path="/"
                  element={
                    <Suspense fallback={<Loader />}>
                      <User
                        tenantTitle={tenantTitle}
                        userId={userInfo?.id}
                        isManager={Boolean(userInfo?.is_manager)}
                      />
                    </Suspense>
                  }
                />
              </Routes>
            </>
          }
        />
      </Routes>
    </div>
  );
};

export default AssignedLearningPage;

const headerWrapperCss = css`
  margin-bottom: ${MIDDLE_MARGIN_PX};
`;

const headerContentWrapperCss = ({ theme }) => css`
  padding-top: 120px;
  padding-bottom: ${LARGE_MARGIN_PX};

  ${theme.breakpoints.up('md')} {
    padding-top: 200px;
  }
`;

const headerTitleCss = css`
  margin-bottom: 0;
  text-align: left;
`;

const HeaderDescription = styled(Typography)`
  margin-top: ${SMALL_MARGIN_PX};
  color: ${({ theme }) => theme.palette.common.gray};
  line-height: 1.5;
  font-size: 18px;
  max-width: 794px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 20px;
    margin-top: ${MIDDLE_MARGIN_PX};
  }
`;
