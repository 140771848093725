import React, { FC } from 'react';

import { styled } from '@mui/material/styles';

import ConditionalBlockLink from '../../../components/ConditionalBlockLink/ConditionalBlockLink';
import { MetaText } from '../../../components/UI/Texts';
import { SMALL_MARGIN_PX, TINY_MARGIN_PX } from '../../../theme';
import {
  MegaMenuLeftLinkSubBlock,
  MegaMenuLeftListSubBlock,
  MegaMenuLeftSubBlock,
} from '../../../types';
import { MegaMenuLeftBlockItemsTypes } from '../types';
import DarkCard from './DarkCard';
import LinkWithArrow from './LinkWithArrow';

interface LeftMegaMenuProps {
  subtitle?: string | null;
  externalLink?: string | null;
  textExternalLink?: string | null;
  itemsType?: string | null;
  items?: (MegaMenuLeftSubBlock | null)[] | null;
}

const LeftMegaMenu: FC<LeftMegaMenuProps> = ({
  subtitle = '',
  externalLink,
  textExternalLink,
  itemsType,
  items,
}) => {
  const listItems =
    itemsType === MegaMenuLeftBlockItemsTypes.ListItem ? (items as MegaMenuLeftLinkSubBlock[]) : [];
  const blockItems =
    itemsType === MegaMenuLeftBlockItemsTypes.BlockItem
      ? (items as MegaMenuLeftListSubBlock[])
      : [];

  return (
    <div>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {Boolean(listItems?.length) && itemsType === MegaMenuLeftBlockItemsTypes.ListItem && (
        <ItemsList>
          {listItems?.map((item, index) => (
            <Item
              data-testid="megamenuListItem"
              key={index}
              withLink={Boolean(item?.link)}
              to={item?.link || undefined}
            >
              {item?.title}
            </Item>
          ))}
        </ItemsList>
      )}
      {Boolean(blockItems?.length) && itemsType === MegaMenuLeftBlockItemsTypes.BlockItem && (
        <CardsList>
          {blockItems?.map((item, index) => (
            <DarkCard
              key={index}
              title={item?.title}
              subtitle={item?.sub_title}
              iconUrl={item?.image_url}
              link={item?.link}
              data-testid="megamenuBlockItem"
            />
          ))}
        </CardsList>
      )}
      {Boolean(externalLink && textExternalLink) && (
        <StyledLinkWithArrow
          data-testid="externalLink"
          title={textExternalLink}
          link={externalLink}
        />
      )}
    </div>
  );
};

export default LeftMegaMenu;

const Subtitle = styled(MetaText)`
  margin-bottom: ${SMALL_MARGIN_PX};
`;

const StyledLinkWithArrow = styled(LinkWithArrow)`
  padding-top: ${SMALL_MARGIN_PX};
  margin-top: -${TINY_MARGIN_PX};
`;

const ItemsList = styled('div')`
  display: flex;
  flex-direction: column;
`;

const Item = styled(ConditionalBlockLink)`
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.common.text.secondaryToPrimary};
  padding-bottom: ${TINY_MARGIN_PX};
  cursor: ${({ withLink }) => (withLink ? 'pointer' : 'default')};
  &:hover {
    color: ${({ theme }) => theme.palette.common.violet};
  }
`;

const CardsList = styled('div')`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: ${SMALL_MARGIN_PX};
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
