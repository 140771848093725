const hostMatches = [
  'bpp',
  'dukece',
  'sustainabilityunlocked',
  'financeunlocked',
  'euromoney',
  'data-scienceunlocked',
  // order matters: skills has to be above xunlocked, because we also have skills.xunlocked host which should be treated as skills
  'skills',
  'xunlocked',
];

export const hostToTenantName = (host) => {
  if (!host) {
    return host;
  }

  const matched = hostMatches.find((match) => host.includes(match))?.replace(/-/g, '');
  return matched;
};
