import React, { FC } from 'react';

import { alpha, styled } from '@mui/material/styles';

import ConditionalBlockLink from '../../../components/ConditionalBlockLink/ConditionalBlockLink';
import { IKImage } from '../../../components/IKImage';
import { MetaText } from '../../../components/UI/Texts';
import { MIDDLE_RADIUS_PX, SMALL_MARGIN_PX, TINY_MARGIN_PX } from '../../../theme';

const SRCSET = [{ width: 32 }];

interface DarkCardProps {
  title?: string | null;
  subtitle?: string | null;
  iconUrl?: string | null;
  link?: string | null;
  'data-testid'?: string;
}

const DarkCard: FC<DarkCardProps> = ({ title, subtitle, iconUrl, link, ...rest }) => {
  return (
    <Wrapper data-testid={rest['data-testid']} withLink={Boolean(link)} to={link || undefined}>
      {iconUrl && <Icon srcset={SRCSET} sizes="100vw" path={iconUrl} />}
      <TextWrapper>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </TextWrapper>
    </Wrapper>
  );
};

export default DarkCard;

const Wrapper = styled(ConditionalBlockLink)`
  display: flex;
  background: ${({ theme }) => theme.palette.common.blockBackground.main};
  border-radius: ${MIDDLE_RADIUS_PX};
  cursor: ${({ withLink }) => (withLink ? 'pointer' : 'default')};
  padding: ${SMALL_MARGIN_PX};
`;

const Icon = styled(IKImage)`
  width: 32px;
  height: 32px;
  margin-right: ${TINY_MARGIN_PX};
`;

const TextWrapper = styled('div')``;

const Title = styled(MetaText)`
  color: ${({ theme }) => theme.palette.common.white};
  font-weight: bold;
`;

const Subtitle = styled(MetaText)`
  color: ${({ theme }) =>
    theme.isSsku
      ? theme.palette.common.text.secondaryToPrimary
      : alpha(theme.palette.common.white, 0.33)};
`;
