import { useEffect, useState } from 'react';

import TagManager from 'react-gtm-module';

import { LearnerInfo } from '../types';
import useAuth from './useAuth';

const useInitGtm = (
  location,
  gtmId?: string | null,
  userInfo?: LearnerInfo | null,
  userLoading?: boolean,
) => {
  const [isInitializedGtm, setIsInitializedGtm] = useState(false);

  const { isInitialized, isLoading } = useAuth();

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      typeof document !== 'undefined' &&
      gtmId !== undefined &&
      gtmId !== null &&
      isInitialized &&
      !isLoading &&
      !userLoading
    ) {
      setIsInitializedGtm(true);
      const tagManagerArgs = {
        gtmId,
        ...(userInfo?.id && {
          dataLayer: {
            userid: userInfo?.id,
            companyId: userInfo?.company?.id || null,
          },
        }),
      };

      TagManager.initialize(tagManagerArgs);
    }
  }, [gtmId, isInitialized, isLoading, userInfo, userLoading]);

  useEffect(() => {
    // @ts-ignore
    if (isInitializedGtm && !userInfo && window?.dataLayer) {
      // @ts-ignore
      window.dataLayer.push({
        event: 'logged-out-page-visit',
      });
    }
  }, [isInitializedGtm, location.pathname, userInfo]);

  return isInitializedGtm;
};

export default useInitGtm;
