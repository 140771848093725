import React, { createContext, FC, ReactNode, useMemo } from 'react';

import { Modal as MUIModal } from '@mui/material';

import LinkedInModal from '../components/LinkedInModal';
import ShareModal from '../components/ShareModal/ShareModal';
import LeaveModal from '../components/Test/LeaveModal';
import VideoModal from '../components/VideoModal/VideoModal';
import ExtendDueDateModal from '../pages/AssignedLearningPage/components/ExtendDueDateModal';
import { NotificationsModal } from '../pages/AssignedLearningPage/components/NotificationsModal';
import ChangeLearnerTeamModal from '../pages/MyAccountPage/components/ChangeLearnerTeamModal';
import CreateTeamModal from '../pages/MyAccountPage/components/CreateTeamModal';
import EditTeamModal from '../pages/MyAccountPage/components/EditTeamModal';
import styled from '../styled';
import { SMALL_MARGIN_PX } from '../theme';
import Confirm from './Confirm';
import useMultipleModals from './useMultipleModals';

export enum Modals {
  LinkedInModal = 'LinkedInModal',
  ShareModal = 'ShareModal',
  LeaveModal = 'LeaveModal',
  VideoModal = 'VideoModal',
  Confirm = 'Confirm',
  ChangeLearnerTeamModal = 'ChangeLearnerTeamModal',
  EditTeamModal = 'EditTeamModal',
  CreateTeamModal = 'CreateTeamModal',
  NotificationsModal = 'NotificationsModal',
  ExtendDueDateModal = 'ExtendDueDateModal',
}

export type IChildrenParams = {
  closeModal: () => void;
  openModal: (name: Modals, params?: {}) => void;
};
export type IModal = {
  closeModal?: IChildrenParams['closeModal'];
  openModal?: IChildrenParams['openModal'];
};

const modalViews = {
  LinkedInModal,
  ShareModal,
  LeaveModal,
  VideoModal,
  Confirm,
  ChangeLearnerTeamModal,
  EditTeamModal,
  CreateTeamModal,
  NotificationsModal,
  ExtendDueDateModal,
};

export const ModalCtx = createContext<IModal>({
  openModal: () => {},
  closeModal: () => {},
});

function Modal<T extends unknown>({
  children,
  ...rest
}: T & { children: ReactNode | ((params: IChildrenParams) => ReactNode) }) {
  const { openModal, closeModal, queue, modalProps } = useMultipleModals();

  const memoizedValue = useMemo(
    () => ({
      openModal,
      closeModal,
    }),
    [openModal, closeModal],
  );

  return (
    <ModalCtx.Provider value={memoizedValue}>
      {typeof children === 'function' ? children({ openModal, closeModal }) : children}
      <StyledMUIModal open={!!queue.length} onClose={closeModal}>
        <>
          {queue.map((name, index) => {
            if (!name) {
              return null;
            }
            const CurrentModal = modalViews[name] as FC<T>;
            return (
              <CurrentModal
                key={index}
                closeModal={closeModal}
                openModal={openModal}
                {...rest}
                {...modalProps[name]}
              />
            );
          })}
        </>
      </StyledMUIModal>
    </ModalCtx.Provider>
  );
}

export default Modal;

const StyledMUIModal = styled(MUIModal)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${SMALL_MARGIN_PX};
`;
