export const sendEventToGTM = (event, variables = {}) => {
  // @ts-ignore
  if (window?.dataLayer) {
    // @ts-ignore
    window.dataLayer.push({
      event,
      ...variables,
    });
  }
};

export const sendDataToGTM = (variables = {}) => {
  // @ts-ignore
  if (window?.dataLayer) {
    // @ts-ignore
    window.dataLayer.push({
      ...variables,
    });
  }
};
