import { DiscoverRoutes } from '../routes';
import { BREAKPOINTS_VALUES } from '../theme';
import { Maybe, Video } from '../types';
import { secondsToHms } from '../utils/dateUtils/secondsToHms';

export const getBreakpointsForSlider = (isPortraitOrientation: boolean) => ({
  [BREAKPOINTS_VALUES.ml]: {
    slidesPerView: isPortraitOrientation ? 6 : 5.2,
  },
  [BREAKPOINTS_VALUES.xl]: {
    slidesPerView: isPortraitOrientation ? 5 : 4.2,
  },
  [BREAKPOINTS_VALUES.lg]: {
    slidesPerView: isPortraitOrientation ? 4 : 3.5,
  },
  [BREAKPOINTS_VALUES.md]: {
    slidesPerView: isPortraitOrientation ? 3 : 2.5,
  },
  [BREAKPOINTS_VALUES.sm]: {
    slidesPerView: isPortraitOrientation ? 3 : 1.6,
    slidesOffsetAfter: isPortraitOrientation ? 0 : 16,
  },
  [BREAKPOINTS_VALUES.xs]: {
    slidesPerView: isPortraitOrientation ? 2.5 : 1.2,
    slidesOffsetAfter: isPortraitOrientation ? 0 : 16,
  },
});

export const formatPathwayVideos = (
  videos: Maybe<Maybe<Video>[]> | undefined,
  progressData,
  existsIn = {},
) => {
  if (!videos || !videos.length) return [];

  return videos.map((video, index) => {
    const id = video?.id as string;
    const videoUrl = video?.video_url || id;
    const link = progressData[id] ? progressData[id].link : `${DiscoverRoutes.Videos}/${videoUrl}`;

    return {
      ...video,
      link,
      user: video?.user?.name,
      userSlug: video?.user?.slug,
      duration: video?.duration ? secondsToHms(video?.duration) : 0,
      progress: progressData[id] ? progressData[id].progress : 0,
      lastPositionPercent: progressData[id] ? progressData[id].lastPositionPercent : 0,
      completed: progressData[id] ? progressData[id].completed : false,
      new: progressData[id]?.new,
      isInWatchlist: existsIn[id],
      location: index + 1,
    };
  });
};

export const calculateProgress = (watchedVideos?: number | null, totalVideos?: number | null) => {
  if (typeof watchedVideos === 'number' && totalVideos) {
    const progress = Math.round((watchedVideos * 100) / totalVideos);
    return progress <= 100 ? progress : 100;
  } else {
    return 0;
  }
};
