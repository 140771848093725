import { gql } from '@apollo/client';

export const GET_ALL_PATHWAYS = gql`
  query GetAllPathways {
    listPathways {
      name
      description
      image_url
    }
  }
`;

export const GET_ALL_AREAS = gql`
  query GetAllAreas($sort: [OrderByClause!]) {
    listBaseVideoCategories(sort: $sort) {
      id
      title
      image_url
      slug
    }
  }
`;

export const GET_CATEGORIES_BY_PARENT = gql`
  query GetCategoriesByParent($filter: VideoCategorySearchFilter) {
    listVideoCategories(filter: $filter) {
      id
      title
      image_url
      description
      slug
      parent_id
    }
  }
`;

export const GET_ALL_CATEGORIES = gql`
  query GetAllCategories {
    listVideoCategories {
      id
      title
      parent_id
    }
  }
`;

export const GET_VIDEO = gql`
  query GetVideoById($id: ID!) {
    getVideo(id: $id) {
      id
      title
      description
      preview_url
      video_url
      executive_summary
      summary
      key_learning_objectives {
        id
        title
      }
      duration
      created_at
      user {
        id
        name
        avatar
        description
      }
      categories {
        id
        title
        slug
      }
    }
  }
`;

export const GET_VIDEOS_BY_EXPERT = gql`
  query GetVideosByExpert($id: ID) {
    listVideos(filter: { user_id: [$id] }) {
      id
      title
      preview_url
      video_url
      duration
    }
  }
`;

export const GET_RELATED_VIDEOS_POST = gql`
  query GetRelatedPostVideos($post_id: ID) {
    listVideosRelatedWithPostByCategories(post_id: $post_id, limit: 4) {
      id
      title
      description
      duration
      preview_url
      video_url
      user {
        id
        name
        slug
      }
      difficulty {
        id
        icon_url
        title
      }
      categories {
        id
        title
        slug
        parent {
          slug
          id
        }
      }
      difficulty {
        id
        icon_url
        title
      }
    }
  }
`;

export const GET_RELATED_VIDEOS = gql`
  query GetRelatedVideos($filter: VideoYouMightAlsoLikeFilter) {
    listVideosYouMightAlsoLike(filter: $filter) {
      id
      title
      description
      duration
      preview_url
      video_url
      user {
        id
        name
        slug
      }
      difficulty {
        id
        icon_url
        title
      }
      categories {
        id
        title
        slug
        parent {
          slug
          id
        }
      }
      difficulty {
        id
        icon_url
        title
      }
    }
  }
`;

export const GET_CONTINUE_WATCHING_VIDEO_LINKS = gql`
  query GetContinueWatchingVideoLinks($filter: VideoContinueWatchingLinksFilter) {
    listContinuingWatchingLinks(filter: $filter) {
      time
      chapter
      url
      video {
        id
      }
    }
  }
`;

export const GET_NEXT_IN_PATHWAY_VIDEO = gql`
  query GetNextVideoInPathway($filter: VideoNextInPathwayFilter) {
    getVideoNextInPathway(filter: $filter) {
      id
      title
      duration
      preview_url
      video_url
      user {
        id
        name
        slug
      }
      categories {
        id
        title
        slug
        parent {
          slug
          id
        }
      }
      difficulty {
        id
        icon_url
        title
      }
      pathways {
        name
        slug
        id
        videos {
          id
          title
          video_url
          difficulty {
            id
            icon_url
            title
          }
        }
      }
    }
  }
`;

export const CHECK_IF_VIDEO_EXISTS_IN_WATCHLIST = gql`
  query CheckIfVideoExistsInWatchlist($filter: VideoExistsInWatchListFilter) {
    VideoExistsInUserWatchList(filter: $filter) {
      exists
    }
  }
`;

export const ADD_VIDEO_TO_WATCHLIST = gql`
  mutation AddVideoToWatchlist($video_id: ID!) {
    AddVideoInWatchList(video_id: $video_id)
  }
`;

export const REMOVE_VIDEO_FROM_WATCHLIST = gql`
  mutation RemoveVideoFromWatchlist($video_id: ID!) {
    DeleteVideoFromWatchList(video_id: $video_id)
  }
`;

export const GET_VIDEO_CATEGORY = gql`
  query GetVideoCategory($filter: UniqueVideoCategoryFilter!) {
    getVideoCategory(filter: $filter) {
      id
      image_url
      title
      description
      slug
      tenants {
        id
        name
      }
      parent {
        id
        title
        slug
      }
      development_goals {
        title
        description
        image
      }
    }
  }
`;

export const GET_VIDEO_BY_SLUG_OR_ID = gql`
  query GetVideoBySlugOrId($filter: VideoSearchFilter) {
    listVideos(filter: $filter) {
      id
      title
      description
      preview_url
      video_url
      verse_video_id
      executive_summary
      summary
      show_bumper
      free_for_all
      searchable
      is_part_of_assigned_learning
      key_learning_objectives {
        id
        title
      }
      duration
      created_at
      user {
        id
        name
        avatar
        description
        slug
        job_title
      }
      categories {
        id
        title
        slug
        parent {
          slug
          id
        }
      }
      difficulty {
        id
        icon_url
        title
      }
      pathways {
        id
        name
        slug
      }
      tenants {
        id
        name
      }
      showing_mode
    }
  }
`;

export const IS_PART_OF_ASSIGNED_LEARNING = gql`
  query IsPartOfAssignedLearning($video_url: String!) {
    isPartOfAssignedLearning(video_url: $video_url)
  }
`;

export const GET_VIDEO_VERSE_ID_BY_SLUG_OR_ID = gql`
  query GetVideoVerseIdBySlugOrId($filter: VideoSearchFilter) {
    listVideos(filter: $filter) {
      id
      verse_video_id
    }
  }
`;

export const GET_VIDEO_BY_SLUG = gql`
  query GetVideoBySlug($filter: VideoSearchFilter) {
    listVideos(filter: $filter) {
      id
      created_at
    }
  }
`;

export const GET_VIDEO_PROGRESS_LIST = gql`
  query GetVideoProgressList($filter: VideoContinueWatchingLinksFilter) {
    listVideoProgress(filter: $filter) {
      time
      chapter
      completed
      last_position
      progress_percentage
      video {
        id
        video_url
        duration
      }
    }
  }
`;

export const GET_RATING_FOR_ENTITY = gql`
  query GetRatingForEntity(
    $entity_type: ReviewableType!
    $entity_id: Int!
    $pagination_option: PaginationOption!
    $include_test_publish_status: Boolean
  ) {
    getListReviewsForEntity(
      entity_type: $entity_type
      entity_id: $entity_id
      pagination_option: $pagination_option
      include_test_publish_status: $include_test_publish_status
    ) {
      general_star_rating
      likes_ratio
    }
  }
`;

export const GET_LEARNER_REVIEW = gql`
  query GetLearnerReview($entity_type: ReviewableType!, $entity_id: Int!) {
    getLearnerReviewOnEntity(entity_type: $entity_type, entity_id: $entity_id) {
      stars_rating
      status
    }
  }
`;

export const GET_LIST_REVIEWS_FOR_ENTITY = gql`
  query GetListReviewsForEntity(
    $entity_type: ReviewableType!
    $entity_id: Int!
    $pagination_option: PaginationOption!
    $include_test_publish_status: Boolean
  ) {
    getListReviewsForEntity(
      entity_type: $entity_type
      entity_id: $entity_id
      pagination_option: $pagination_option
      include_test_publish_status: $include_test_publish_status
    ) {
      general_star_rating
      count_reviews
      reviews {
        stars_rating
        created_at
        comment
        learner {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

export const CREATE_REVIEW = gql`
  mutation CreateReview($input: ReviewCreateInput!) {
    createReview(input: $input) {
      stars_rating
    }
  }
`;

export const GET_PATHWAYS_CONTAINING_VIDEO = gql`
  query GetPathwaysContainingVideo($filter: VideoNextInPathwayFilter) {
    getPathwaysContainingVideo(filter: $filter) {
      id
      slug
      count_videos
      name
      image_url
      quizes {
        id
        title
        time_for_pass
      }
      available_quizes {
        id
        title
        time_for_pass
      }
      videos {
        id
        title
        preview_url
        video_url
        duration
      }
    }
  }
`;

export const GET_VIDEO_QUESTION = gql`
  query GetVideoQuestion($video_id: Int!, $include_test_publish_status: Boolean) {
    GetVideoQuestionV2(
      video_id: $video_id
      include_test_publish_status: $include_test_publish_status
    ) {
      id
      title
      image
      frontend_type
      description
      answers {
        id
        title
      }
    }
  }
`;

export const GET_TWO_LATEST_VIDEOS = gql`
  query GetTwoLatestVideos {
    getLastAddedTwoVideosFromDifferentExperts {
      id
      title
      description
      video_url
      preview_url
    }
  }
`;

export const GET_NEXT_VIDEO_IN_VIDEO_ASL = gql`
  query GetNextVideoInVideoAsl($video_id: Int!) {
    getVideoNextInVideoAsl(video_id: $video_id) {
      video_assignments {
        id
        title
        due_date
        has_test
        continue_watching_videos {
          id
          title
          video_url
        }
      }
    }
  }
`;

export const CREATE_CUSTOMER_SUPPORT_REQUEST = gql`
  mutation CreateCustomerSupportRequest($input: CustomerSupportRequestInput!) {
    createCustomerSupportRequest(input: $input)
  }
`;
