import { useQuery } from '@apollo/client';

import { GET_AUTH0_LOGIN_DOMAIN } from '../graphql/user';
import { Query, QueryGetAuth0LoginDomainArgs } from '../types';

const useGetLoginDomainByConnection = (connection: string) => {
  const { data, loading, called } = useQuery<
    Pick<Query, 'getAuth0LoginDomain'>,
    QueryGetAuth0LoginDomainArgs
  >(GET_AUTH0_LOGIN_DOMAIN, {
    variables: {
      connection: connection,
    },
    skip: !connection,
  });

  return {
    loadingDomain: connection && (loading || !called),
    domain: data?.getAuth0LoginDomain,
  };
};

export default useGetLoginDomainByConnection;
