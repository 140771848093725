import React, { FC, ReactNode } from 'react';

import { alpha } from '@mui/material/styles';

import useTenantTranslation from '../../hooks/useTenantTranslation';
import styled from '../../styled';

interface SwitchProps {
  value: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onOff?: boolean;
  'data-testid'?: string;
  id?: string;
  icon?: ReactNode;
}

const Switch: FC<SwitchProps> = ({
  value = false,
  onChange = () => {},
  onOff = false,
  id = 'switch',
  icon,
  'data-testid': dataTestid,
  ...rest
}) => {
  const { t } = useTenantTranslation();

  return (
    <Wrapper {...rest}>
      <Input
        data-testid={dataTestid || 'switcher'}
        type="checkbox"
        id={id}
        onChange={onChange}
        checked={value}
      />
      <Label className="switch_label" htmlFor={id} onOff={onOff}>
        {t('input.label.toggle')}
        <Slider>{icon}</Slider>
      </Label>
    </Wrapper>
  );
};

export default Switch;

const Wrapper = styled('div')`
  display: flex;
  font-size: 0;
`;
const Input = styled('input')`
  height: 0;
  width: 0;
  visibility: hidden;
  margin: 0;

  &:checked + .switch_label span {
    left: calc(100% - 28px);
    background: ${({ theme }) => theme.palette.common.primary};
  }
`;
const Label = styled('label', {
  shouldForwardProp: (prop) => prop !== 'onOff',
})`
  cursor: pointer;
  text-indent: -9999px;
  width: 58px;
  height: 32px;
  background: ${({ theme }) => theme.palette.common.violetAnalyticsCard};
  display: block;
  font-size: 16px;
  border-radius: 999em;
  position: relative;
`;

const Slider = styled('span')`
  position: absolute;
  top: 4px;
  left: 4px;
  width: 24px;
  height: 24px;
  background: ${({ onOff, theme }) =>
    onOff ? alpha(theme.palette.common.white, 0.3) : theme.palette.common.primary};
  border-radius: 100%;
  transition: 0.3s;
`;
