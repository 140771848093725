import React from 'react';

import CustomBlocks from '../../blocks/CustomBlocks/CustomBlocks';
import { RM_PAGES } from '../../const';
import { useRmMetadata } from '../../context/RmContext';
import { Block, LearnerInfo, Tenant } from '../../types';

interface CustomPageProps {
  blocks: (Block | null)[] | null;
  tenant?: Tenant | null;
  userInfo?: LearnerInfo;
  preventLoader?: boolean;
  blocksLoading?: boolean;
  canonicalUrl?: string;
}

const CustomPage: React.FC<CustomPageProps> = ({
  blocks,
  preventLoader,
  tenant,
  userInfo,
  blocksLoading,
  canonicalUrl,
}) => {
  useRmMetadata({ page: RM_PAGES.CustomPage });

  return (
    <CustomBlocks
      blocks={blocks}
      preventLoader={preventLoader}
      tenant={tenant}
      userInfo={userInfo}
      blocksLoading={blocksLoading}
      canonicalUrl={canonicalUrl}
    />
  );
};

export default CustomPage;
