import React, { createContext, useCallback, useContext, useState } from 'react';

import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import styled from '../styled';

export interface IAlert {
  message: string;
  duration?: number;
  severity?: Severity;
}

interface IAlertCtx {
  alert: null | IAlert;
  showAlert: (alert: IAlert) => void;
  hideAlert: () => void;
}

export enum Severity {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

const AlertCtx = createContext<IAlertCtx>({
  alert: null,
  showAlert: () => {},
  hideAlert: () => {},
});

export function useAlert() {
  return useContext(AlertCtx);
}

export const WithAlert = ({ children }) => {
  const { alert, hideAlert } = useAlert();

  return (
    <>
      {children}
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        open={Boolean(alert?.message)}
        autoHideDuration={alert?.duration}
        onClose={hideAlert}
      >
        <StyledAlert onClose={hideAlert} severity={alert?.severity} variant="filled">
          {alert?.message}
        </StyledAlert>
      </Snackbar>
    </>
  );
};

const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState<null | IAlert>(null);

  const showAlert = useCallback((alert) => {
    const defaultAlertOptions = { message: '', duration: 3000, severity: Severity.Success };
    setAlert({ ...defaultAlertOptions, ...alert });
  }, []);
  const hideAlert = useCallback((event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert(null);
  }, []);
  return <AlertCtx.Provider value={{ showAlert, hideAlert, alert }}>{children}</AlertCtx.Provider>;
};

export default AlertProvider;

const StyledAlert = styled(MuiAlert)`
  & .MuiIconButton-root {
    padding: 5px;
  }
`;
