import React, { FC, useContext, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Loader } from '../../components/Loader';
import useScriptLoader, { SCRIPTS } from '../../context/ScriptLoader/useScriptLoader';
import { TenantCtx } from '../../context/TenantProvider';
import useIsSsku from '../../hooks/tenant/useIsSsku';
import useAuth from '../../hooks/useAuth';
import UserCom from '../../utils/UserCom';

interface LogoutPageProps {
  loadingUser: boolean;
}

const LogoutPage: FC<LogoutPageProps> = ({ loadingUser }) => {
  const navigate = useNavigate();
  const { logout, isAuthenticated, isLoading, isInitialized } = useAuth();
  const { isLoaded: isUserComLoaded } = useScriptLoader(SCRIPTS.USER_COM);
  const isSsku = useIsSsku();
  const { config } = useContext(TenantCtx);

  useEffect(() => {
    const handleLogout = async () => {
      if (isInitialized && !isLoading && !loadingUser) {
        if (isAuthenticated) {
          if (isUserComLoaded) {
            await UserCom.resetAuth();
          }
          logout();
        } else {
          navigate(isSsku ? config?.logoutUrl : '/', { replace: true });
        }
      }
    };

    handleLogout();
  }, [
    isInitialized,
    isLoading,
    isAuthenticated,
    logout,
    navigate,
    loadingUser,
    isUserComLoaded,
    isSsku,
    config,
  ]);

  return <Loader />;
};

export default LogoutPage;
