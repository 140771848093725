import { DiscoverRoutes } from './routes';

export const AUTH_APP_ID_KEY = 'auth_app_id';
export const NON_RENEWING_SUBSCRIPTION_KEY = 'Non renewing';
export const DEFAULT_LOCALE = 'en';

export enum RM_PAGES {
  VideoPage = 'VideoPage',
  PathwayPage = 'PathwayPage',
  ExpertPage = 'ExpertPage',
  TestPage = 'TestPage',
  TermPage = 'TermPage',
  CategoryPage = 'CategoryPage',
  CourseSharePage = 'CourseSharePage',
  PathwaySharePage = 'PathwaySharePage',
  CoursePage = 'CoursePage',
  CourseProgressPage = 'CourseProgressPage',
  TeamPage = 'TeamPage',
  InviteToTeamPage = 'InviteToTeamPage',
  SinglePlanPage = 'SinglePlanPage',
  JoinTeamPage = 'JoinTeamPage',
  CustomPage = 'CustomPage',
  AslSharePage = 'AslSharePage',
  CaseStudyPage = 'CaseStudyPage',
}

export const DEFAULT_DISCOVER_URL = '/discover';
export const CASE_STUDY_PATH = `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Pathways}/:pathwaySlug/case-study/:studyId`;
export const buildPathwayRoute = (slug) =>
  `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Pathways}/${slug}`;
export const buildCaseStudyRoute = (pathwaySlug, studyId) =>
  `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Pathways}/${pathwaySlug}/case-study/${studyId}`;
export const HERO_IMG_SRC_SET = [
  { width: 500, quality: 80 },
  { width: 800, quality: 80 },
  { width: 1100, quality: 80 },
  { width: 1200, quality: 80 },
  { width: 1920, quality: 80 },
];
export const LAST_VIEWED_PATHWAY_SLUG_COOKIE_NAME = 'last_viewed_pathway_slug';
export const PRIORITY_PATHWAY_SLUG_COOKIE_NAME = 'priority_pathway_slug';

export enum USER_PROGRESS_ENTITY {
  Video = 'video',
  Pathway = 'pathway',
  Tenant = 'tenant',
}

export enum USER_PROGRESS_EVENT {
  VideoWatched = 'videoWatched',
  TestPassed = 'testPassed',
}

export const LOGIN_DOMAIN_COOKIE_NAME = 'auth_login_domain';

export enum FILTERS {
  Expert = 'user',
  Duration = 'duration_interval',
  Difficulty = 'difficulty.id',
  SearchType = 'searchable_type',
  Letter = 'letter_index',
  Category = 'categories.lvl0',
  Subcategory = 'categories.lvl1',
  Subtitles = 'subtitles',
}

export const MY_ACCOUNT_ROWS_PER_PAGE = 'MY_ACCOUNT_ROWS_PER_PAGE';
export const ASSIGNMENT_ROWS_PER_PAGE = 'ASSIGNMENT_ROWS_PER_PAGE';
export const ANALYTICS_ROWS_PER_PAGE = 'ANALYTICS_ROWS_PER_PAGE';
export const ALGOLIA_USER_TOKEN_NAME = 'algoliaToken';
export const LANG_COOKIE_KEY = 'lang';
export const MODE_COOKIE_KEY = 'mode';
export const CAPTIONS_LANG_COOKIE_NAME = 'captionsLang';
export const PLAY_RATE_COOKIE_NAME = 'playRate';
